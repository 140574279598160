import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2023/4E/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2023/4E/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2023/4E/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2023/4E/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly4E2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/4E/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/4E/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/4E/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/4E/4.webp`;
  
  

  const photos = [
          {
                    src: p4,
                    source: p4,
                    rows: 1,
                    cols: 2,
                },
    {
        src: p3,
        source: p3,
        rows: 2,
        cols: 1,
    }, 
    {
      src: p1,
      source: p1,
      rows: 2,
      cols: 1,
    },

    
    {
          src: p2,
          source:p2,
          rows: 2,
          cols: 1,
        },
   
     
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Engineers’ Day
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 4E   Date:19 September 2023 & 20 September 2023
  <br/><br/>
        </Typography>

        <Typography variant={'subtitle1'} align={'justify'}>
        "Science is about knowing, engineering is about doing." Engineers’ day holds a special significance as it marks the birth anniversary of Sir Mokshagundam Visvesvaraya, one of India's most illustrious engineers and visionaries. 
        <br></br>
        The students of Class 4E presented their assembly on the topic, Engineers’ day on Tuesday, 19 September 2023 and Wednesday, 20 September 2023. The assembly commenced with a brief introduction about Engineers’ day, when and why it is celebrated.  Students presented a melodious   song to appreciate and pay respect to the extraordinary job of engineers in various fields like mechanical engineering, civil engineering, computer engineering, electrical engineering and so on.  The assembly concluded by congratulating all the engineers and salute their dedication, precision,  ingenuity and for being the driving forces behind the progress of our nation.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Science can amuse and fascinate us all, but it is engineering that changes the world.”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly4E2023;